<template>
  <div class="an-main">
    <div class="an-top">
      <div class="an-con con-left">
        <module-one></module-one>
      </div>
      <div class="an-con con-right">
        <module-two ref="moduleIcon" :type="1"></module-two>
      </div>
    </div>
    <div class="an-bottom">
      <div class="an-con con-left">
        <module-three ref="moduleTable" :type="1"></module-three>
      </div>
      <div class="an-con con-right" style="background: none;padding: 0">
        <module-four ref="moduleStatistics" :type="1"></module-four>
      </div>
    </div>
  </div>
</template>

<script>
  import moduleOne from "../common/components/moduleOne"
  import moduleTwo from "../common/components/moduleTwo"
  import moduleThree from "../common/components/moduleThree";
  import moduleFour from "../common/components/moduleFour";
  export default {
    name: "index",
    components:{
      moduleOne,moduleTwo,moduleThree,moduleFour
    },
    data(){
      return{}
    },
    methods:{
      pageGet(type){
        this.$refs.moduleTable.pageGet(type)
      },
      clickRow(data){
        this.$refs.moduleStatistics.getSensorById(data)
      },
      getIconList(time){
        this.$refs.moduleIcon.getIconList(time)
      }
    }
  }
</script>

<style scoped>
  .an-main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
  }
  .an-top {
    width: 100%;
    height: 22%;
    display: flex;
    justify-content: space-between;
  }
  .an-con{
    padding: 20px;
    height: 100%;
    background: #fff;
  }
  .an-bottom{
    width: 100%;
    height: 77%;
    display: flex;
    justify-content: space-between;
  }
  .con-left{
    width: 30%;
  }
  .con-right{
    width: 69%;
  }
  /deep/ .an-title{
    width: 100%;
    height: 30px;
    line-height: 30px;
    display: flex;
    align-content: center;
    align-items: center;
    font-size: 16px;
  }
  /deep/ .an-title i{
    color: #1A67D0;
    font-size: 20px;
    margin-right: 5px;
  }
  /deep/ .an-title span{
    color: #1A67D0;
    font-weight: bold;
  }
</style>
